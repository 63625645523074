
html
  min-height: 100vh
  min-width: 100vw

body
  margin: 0
  padding: 0
  color: #BBBBBB
  font-family: sans-serif


p
  margin-top: 0.5vh
  margin-bottom: 0
a
  display: inline
  text-decoration: none

a:link, a:visited
  color: #BBBBBB
  outline: none
  font-weight: bold

a:hover
  color: #727272
  outline: none
  font-weight: bold

a:active
  color: #727272
  outline: none

.base 
  min-height: 100vh
  min-width: 100vw
  background-color: #2F343F

.splash-console
  @media screen and (max-width: 1000px)
    padding-left: 5%
    padding-right: 5%
  padding-left: 30%
  padding-right: 30%
  padding-top: 0.5vh

.splash-console > h2
  padding-bottom: 3vh

.console-item
  padding-bottom: 4vh

.console-command
  margin-bottom: 2.8vh
  opacity: 0.75

.console-output
  padding-left: 3vh

span.console-name
  color: #007f2c
  font-weight: bold

.project
  margin-bottom: 2vh

.project-headline > p
  margin-top: 0
  margin-bottom: 0
  display: inline


.social-wrapper
  display: inline
  margin-right: 1vw
  
.volunteering-item
  margin-bottom: 3vh

.volunteering-item > *
  margin-top: 0.1vh
  margin-bottom: 0.1vh


.jobs-item
  margin-bottom: 2vh

.jobs-item > p
  margin-top: .5vh

span.job-period
  width: 100%

.hero
  text-align: center
  border-colour: red
  border-weight: 1
  margin-bottom: 4vh

.social-list-container
  margin-top: 2vh

.cursor
  display: inline-block
  background: #BBBBBB
  line-height: 2vh
  margin-left: 10px
  margin-bottom: -4px
  width: 7px
  height: 20px
  min-width: 0.6vw
  height: fill-parent
